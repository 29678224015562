.textfield {
  width: 160px;
}

input {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  font-size: xx-large;
}

input:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.content-container {
  padding: 0%;
  background-color: lightgrey;
  font-size: 24px;
}
.left-panel {
  background-color: lightgrey;
}
.middle-panel {
  background-color: lightgrey
}
.right-panel {
  background-color: lightgrey;
}
.box {
  border: 0px solid black;
}
.row {
  display: flex;
  height: 100%;
}
.left-panel {
  flex: 5;
}
.right-panel {
  flex: 4;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
